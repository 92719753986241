.toast-notification-wrapper {
	border-radius: 0px 0px 16px 16px !important;
}

.toast-info-wrapper {
	background-color: #0C6BF3 !important;
}
.toast-warn-wrapper {
	background-color: #FFCC00 !important;
}
.toast-error-wrapper {
	background-color: #E5240B !important;
}

.toast-notification-body {
	gap: 16px;
}
.toast-notification-body > :nth-child(1) > img {
	width: 28px;
	height: 28px;
	margin-right: 16px;
}
.toast-notification-body > :nth-child(2) {
}
.toast-notification-body > :nth-child(2) > p {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	margin-bottom: 0;
	color: white;
}
.toast-notification-body > :nth-child(2) > p > a {
	text-decoration: underline;
	color: white;
	text-underline-offset: 1px;
}

.toast-body-warn > :nth-child(2) > p {
	color: black !important;
}
.toast-body-warn > :nth-child(2) > p > a {
	color: black !important;
	text-decoration: underline;
	text-underline-offset: 1px;
}