.buono-btn {
  height: 55px;
  width: 350px;
  float: right;
  font-weight: 500;
  font-size: 20px;
}

.buono-img {
  height: 600px;
}
@media (max-width: 605px) {
  .buono-img {
    height: 300px;
  }
}
