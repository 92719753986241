
.form-candidatura {
    margin-top: 30px;
    max-width: 900px;
    width: 100%;
}

.extended-userprofile {
    background-color: transparent;
    border: none;
    margin-left: auto;
    color: #007bff;
}
.extended-userprofile:hover {
    text-decoration: underline;
}

#success {
	font-size: 18px;
}