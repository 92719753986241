.certificate {
	margin: 3vh 0vh;
}
.hidden {
	display: none;
}
.title-attestati {
}
.title-attestati > p {
	height: 100%;
}
.checkmark-attestati {
	height: 10vh;
}
.checkmark-attestati > img {
	margin-top: 50%;
	height: 4vh;
	width: 4vh;
}