.event-list {
    padding: 20px 40px;
    box-sizing: border-box;
    overflow-x: auto;
}

.event-list table tbody tr:hover {
    cursor: pointer;
    background-color: #7e98b5;
    color: white;
}

.event-list .pagination-salto-prenotazione {
    margin: 0 auto;
}