.booked-event {
    margin: 10px 0 30px 0;
    border-top: 2px solid #007bff;
}

.booked-event .event-booked-title {
    margin-top: 15px;
}

.booked-event .event-booked-description {
    max-width: 790px;
}

.booked-event .event-booked-description .continue {
    cursor: pointer;
    color: #007bff;
}

.booked-event .show-details {
    margin-top: 30px;
}

.booked-event .show-details a {
    color: white;
    background-color: #007bff;
    padding: 10px 50px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
}

.card-holder > :nth-child(odd) {
	margin-left: 10%;
}