.search-box-prenotazione-salto {
    background-color: transparent;
    color: white;
    border-radius: 5px;
    margin-bottom: 20px;
}

.search-box-prenotazione-salto input {
    /* background-color: white; */
    outline: none;
}

.search-box-prenotazione-salto .add-new-user {
    font-size: 20px;
    background-color: green;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-top: 20px;
}

.search-box-prenotazione-salto .delete-values {
    font-size: 20px;
    background-color: '#0065F2';
    border-radius: 50%;
    color: white;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

}

.search-box-prenotazione-salto .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -12px);
    cursor: pointer;
}

.btn-new-booking-salto {
    background-color: white;
    color: #0065f2;
    border: 1px solid #0065f2;
    border-radius: 5px;
    padding: 5px 80px;
    width: 100%;
    max-width: 320px;
}

.records-export {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.records-find {
    padding: 10px;
    font-size: 20px;
}

.export-table {
    width: 50px;
    height: 50px;
    cursor: pointer;
}


.serach-btn-salto {
    color: white;
    background-color: #0065f2;
    border: none;
    border-radius: 8px;
    width: 100%;
    min-height: 35px;
    cursor: pointer;
    font-size: 20px;
    max-width: 320px;
}

.aggiuni-utente {
    background-color: #0065F2;
    color: white;
    border-radius: 12px;
    font-size: 18px;
    padding: 6px;
    width: 300px;
    margin: 0;
    outline: none;
    border: none;
}

.aggiuni-utente:focus {
    outline: none;
}

@media (min-width: 768px) {
    .search-box-prenotazione-salto .add-new-user {
        position: absolute;
        right: 11px;
        top: 18px;
        margin-top: 0;
    }
}