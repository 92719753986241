.booking {
    font-size: 40px;
    color: #16181E;
    font-weight: 600;
    padding-left: 10px;
    line-height: 48px;
}

.go-to-booked-events {
    padding-left: 12px;
    margin: 30px 0 0 0;
}

.go-to-booked-events a {
    color: #007bff;
    background-color: white;
    border: 4px solid #007bff;
    padding: 10px 50px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
}

.program {
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
}

.container-events {
    position: relative;
    width: 30%;
}

.container-events .select-events {
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 80%;
    height: 28px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 500;
    color: #001C42;
}

.container-events .select-events .event-option {
    font-size: 24px;
    font-weight: 500;
    color: #001C42;
}

.container-events svg {
    position: absolute;
    top: 50%;
    right: 80px;
    transform: translateY(-50%);
    margin-top: 2px;
    z-index: -1;
}

.container-search-arguments {
    display: flex;
    margin-top: 10px;
    width: 100%;
    align-items: baseline;
    flex-wrap: wrap;
}

.container-search-arguments .search-box {
    background-color: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    margin-top: 20px;
    margin-right: 10px;
    position: relative;
    align-items: center;
    width: 320px;
    margin-bottom: 10px;
}

.search-box input {
    width: 92%;
    height: 40px;
    border: none;
    border-bottom: 1px solid #001C42;
    margin: 15px 5px;
    padding: 10px;
    outline: none;
    background-color: #FFFFFF;
    border-radius: 0%;
    box-sizing: border-box;
}

.search-box svg {
    position: absolute;
    top: 50%;
    right: 22px;
    transform: translateY(-50%);
    margin-top: 2px;
    cursor: pointer;
}

.container-day-hour {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.container-day-label {
    max-width: 170px;
    margin-right: 20px;
}

.container-hour-label {
    max-width: 170px;
    min-width: 140px;
}

.container-hour-label .container-day {
    position: relative;
}

.container-day-label .container-day {
    position: relative;
}

.container-day .select-day {
    border: none;
    background-color: #0065f2;
    -moz-appearance: none;
    appearance: none;
    width: 90%;
    height: 38px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: white;
    border-radius: 20px;
}

.container-day svg {
    position: absolute;
    top: 50%;
    right: 28px;
    transform: translateY(-50%);
    margin-top: 2px;
    z-index: 1;
    width: 16px;
    height: 16px;
}

.container-day svg path {
    fill: white;
}

.results-box {
    background-color: rgba(0, 101, 242, 0.1);
    padding: 10px;
    box-sizing: border-box;
    margin-top: 40px;
    margin-left: 10px;
}

.results-box p span {
    margin-left: 18px;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.pagination-position {
    width: 30%;
    margin: 0 auto;
}

.other-results {
    display: none;
}

.day-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

@media (max-width: 450px) {
    .container-events {
        width: 100%;
    }

    .container-events svg {
        right: 60px;
    }

    .container-search-arguments {
        flex-direction: column;
        justify-content: center;
        margin-top: 5px;
        padding-left: 10px;
        align-items: flex-start;
    }

    .container-search-arguments .search-box {
        margin-bottom: 10px;
        align-self: stretch;
        width: 100%;
        margin-top: 10px;
    }

    .container-day-hour {
        justify-content: center;
    }

    .container-day-hour .container-day-label {
        width: 100%;
        max-width: 100%;
    }

    .container-day-hour .container-day-label .container-day {
        width: 100%;
    }

    .container-day-hour .container-hour-label {
        width: 100%;
        max-width: 100%;
        margin-right: 20px;
    }

    .container-day-hour .container-hour-label .container-day {
        width: 100%;
    }

    .pagination-position {
        display: none;
    }

    .other-results {
        display: block;
    }

    .other-results button {
        background-color: white;
        border: 4px solid #0065F2;
        border-radius: 12px;
        font-size: 18px;
        padding: 6px;
        width: 100%;
        color: #0065F2;
        margin: 0 11px;
    }
}

.popup-guide-modal-container {
    margin: 3vh;
    text-align: center;
}

.popup-guide-modal-container>p {
    text-align: left;
}

.popup-guide-modal-container>button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    padding: 3px 20px;
    margin: 10px 0px;
}