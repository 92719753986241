.live-search-container {
    position: relative;
}

.live-search-container .live-search {
    position: absolute;
    top: 100%;
    z-index: 100;
    right: 15px;
    left: 15px;
    background-color: white;
}

.live-search-container .live-search div {
    padding: 5px;
    cursor: pointer;
}
.live-search-container .live-search div:hover {
    background-color: whitesmoke;
}

.live-search-container .live-search div.selected {
    background-color: #007bff;
    color: white;
}