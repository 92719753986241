
.clean-filter-bookings {
	font-size: 1rem;
	text-decoration: underline;
	color: #0065f2;
	cursor: pointer;
}

@media (max-width: 650px) {
	.container-search-arguments {
		padding-left: 0;
	}
	.bookings-day-hour {
		flex-wrap: nowrap;
		margin-left: 0;
	}
	.container-day-label, .container-hour-label {
		width: 40% !important;
	}
}