
.events-container > .card-container-small:nth-child(odd) {
	margin-left: 0px;
}

.card-container {
	display: flex;
	background-color: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.14);
	border-radius: 8px;
	height: 136px;
}
.card-container-small {
	margin-left: 16px;	
    margin-bottom: 16px;
	height: 136px;
	width: 300px;
}
.card-container-wide {
	width: 616px;
}

.side-color {
	width: 136px;
	height: 136px;
	color: inherit;

	display: flex;
	flex-direction: column;
	align-items: center;	
	justify-content: center;
	border-radius: 8px 0px 0px 8px;
}
.side-color > * {
	text-align: center;
	color: inherit;
	width: 100%;
	height: auto;
	margin-bottom: 0 !important;
}
.data-evento-side {
	margin-top: 10px;
	font-size: 10px;
	line-height: 20px;
	letter-spacing: 3px;
	font-weight: 500;
}
.ora-evento-side {
	font-size: 32px;
	line-height: 38px;
	letter-spacing: -0.4px;
	font-weight: 600;
}
.luogo-sala-side {
	margin-top: 24px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
}
.citta-padiglione-side {
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	margin-bottom: 10px !important;
}

.main-body {
	width: 164px;
	height: 136px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.wide-main-body {
	width: 480px;
	align-content: center;
}

.evento-text-body{
	width: 100%;
}
.titolo-evento-body {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	margin: 16px 0 0 16px;
}
.sottotitolo-evento-body {
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	margin: 0px 20px 0px 8px;
}

.wide-main-body > .evento-text-body{
	width: 328px;
}
.wide-main-body > .evento-text-body> .titolo-evento-body {
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;

	margin-left: 8px;
	margin-bottom: 4px;
}
.wide-main-body > .evento-text-body> .sottotitolo-evento-body {
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;

	margin-left: 8px;
	margin-bottom: 22px;
}

.icons-body {
	padding: 0 16px;
	width: 100%;
	margin-bottom: 12px;
	align-self: flex-end;
}
.wide-main-body > .icons-body {
	width: 152px;
	align-self: auto;
}

.icons-body > div {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-right: 10%;
}

.icons-body > div > * {
}

.aggiungi-body {
	max-width: 20px;
	max-height: 19px;
	height: auto;
	cursor: pointer;
}
.elimina-body {
	max-width: 20px;
	max-height: 19px;
	height: auto;
	cursor: pointer;
}
.dettagli-body {
	max-width: 20px;
	max-height: 19px;
	height: auto;
	cursor: pointer;
}

.text-success {
	color: green;
}

@media (max-width: 650px) {
	.event-card-row {
		border: none !important;
	}
	.events-container {		
		margin-bottom: 0 !important;
	}

	.events-container {
		width: 100% !important;
	}
	.card-container {
		margin: 0 !important;
		margin-bottom: 5% !important;
		width: 100% !important;
		height: auto !important;
	}
	.side-color {
		width: 45% !important;
		height: 100%;
	}
	.data-evento-side {
		font-size: 1rem;
		font-weight: 300;
	}
	.ora-evento-side {
		font-size: 2rem;
		font-weight: 600;
	}
	.luogo-sala-side {
		margin-top: 20%;
		font-size: 1.2rem;
		font-weight: 300;
	}
	.citta-padiglione-side {
		font-weight: 300;
	}

	.wide-main-body > .evento-text-body {
		width: 100%;
	}

	.side-color > p, .main-body > * > * {
		line-height: initial !important;
	}
	.wide-main-body {
		align-content: initial !important;
	}
	.main-body {
		width: 55%;
		flex-wrap: wrap;
		height: 100%;
	}
	.evento-text-body{
		text-align: left;
		margin-top: 1.5vh;
		width: 100%;
	}
	.titolo-evento-body {
		font-size: 1.2rem;
		font-weight: bold;
	}
	.sottotitolo-evento-body {
		font-size: 0.6rem;
		font-weight: 300 !important;
	}
	.icons-body {
		width: 100% !important;
		align-self: flex-end !important;
		padding: 0 16px;
	}
	.aggiungi-body {
		height: 20px !important;
		max-height: initial !important;
		max-width: initial !important;
	}
	.elimina-body {
		height: 20px !important;
		max-height: initial !important;
		max-width: initial !important;
	}
	.dettagli-body {
		height: 20px !important;
		max-height: initial !important;
		max-width: initial !important;
	}
  }