.newsletter-modal-container {
	display: flex;
}
.newsletter-right-image {
	width: 500px;
	height: 500px;
	background-color: #e5e5f7;
	opacity: 0.8;
	background: repeating-linear-gradient( -45deg, #8e93ff, #c3c6ff 2px, #e5e5f7 2px, #e5e5f7 15px );
	margin-right: 15px;
}
.newsletter-left {
	width: 300px;
	height: 500px;
	padding-right: 20px;
}
.newsletter-header {
	margin-top: -12px;
}
.page-1 {
	margin-top: 15px;
}
.newsletter-body {
	margin-top: 0px;
}

.newsletter-checkbox-container {
	margin-top: 15px;
	margin-bottom: 30px;
}
.checkbox-row {
	display: flex;
	height: 40px;
	width: 100%;
	margin: 15px 0;
	align-items: center;
}
.checkbox-row > label {
	width: 80%;
	margin-bottom: 0 !important;
	align-self: center;
	font-weight: 100;
	margin-left: 10px;
}
.checkbox-row > input {
	height: 80% !important;
	width: 10% !important;
}
.checkbox-row > input:hover {
	accent-color: rgb(189, 193, 255) !important;
	background-color: rgb(189, 193, 255) !important;
	color: #ffff !important;
}
.checkbox-row > input:checked {
	accent-color: #99c1fa !important;
}

.newsletter-title {
	font-size: 32px !important;
	font-weight: 600 !important;
	line-height: 38px !important; 
}

.newsletter-sub-title{
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 24px !important;
}

.newsletter-p {
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
}

.cultural-consent {
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 18px !important;
}

@media(max-width: 650px) {
	.newsletter-left {
		width: 100%;
		padding: 0 !important;
	}
}