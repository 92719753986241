.navigation-dashboard {
	margin-bottom: 3rem !important;
	display: block;
}

.message-booking {
    font-size: 32px;
	line-height: 38px;
    font-weight: 600;
	margin-bottom: 8px;
}

.explain {
	font-size: 14px;
	font-weight: 300;
	line-height: 18px;
	margin-bottom: 32px;
}

.go-to-programmes {
	width: 288px;
	height: 48px;
	margin-bottom: 72px;
}

.go-to-programmes a {
    color: #007bff;
    background-color: white;
	border: 4px solid #007bff;
    padding: 10px 50px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;

    display: block;
    text-align: center;
    margin: 0 auto;
}

.desc-event {
	margin-bottom: 8px;
}
.desc-event-title {
	font-size: 18px;
	line-height: 22px;
	font-weight: 500;
	margin-bottom: 0;
	line-height: 22px;
}

.desc-event-body {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.event-card-row {
	display: flex;
	flex-direction: row;
	border-top: 1px solid #165BE2;
	justify-content: space-between;
}

.date-text {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	margin: 10px 97.5px 0px 89.5px;
	white-space: nowrap;
}

.events-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-wrap: wrap;
	margin-top: 10px;
	margin-bottom: 32px;
}

.booking-page {
	width: 100%;
}

@media (max-width : 570px) {
	.go-to-programmes {
		width: 100%;
	}
}