.admin-pages-main {
    margin: 40px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 70vh;
}


.users-admin-page {
    max-width: 100%;
}

.search-users {
    border-radius: 15px;
    margin-bottom: 25px;
    background-color: transparent;
}

.delete-values {
    font-size: 20px;
    background-color: #0065f2;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -12px);
    cursor: pointer;
}

.records-export {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.records-find {
    padding: 15px;
    font-size: 25px;
    font-weight: 600;
}

.btn-new-booking-sponsor, .btn-create-new-user {
    background-color: white;
    color: #0065f2;
    border: 1px solid #0065f2;
    border-radius: 5px;
    padding: 5px 70px;
}

.search-btn-users {
    color: white;
    background-color: #0065f2;
    border: none;
    border-radius: 8px;
    width: 100%;
    min-height: 35px;
    cursor: pointer;
    font-size: 20px;
    max-width: 320px;
}

.users-list, .buono-list {
    padding: 10px;
    overflow-x: auto;
    width: 1440px;
}

.users-list .table-striped>thead>tr>th, .buono-list .table-striped>thead>tr>th {
    background-color: #0065f2;
    color: white;
}

.export-table {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

@keyframes downloading {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
        background-color: green;
    }

    100% {
        transform: translateY(0);
    }
}

.downloading {
    animation: downloading 5s ease-in-out infinite;
}

.users-edit {
    vertical-align: middle;
    margin-left: 10px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-buono {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.request-buono {
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: #0065f2;
    color: white;
    border-radius: 15px;
    align-items: center;
    white-space: nowrap;
    padding: 5px;
}

.download-buono {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
}

.delete-user {
    cursor: pointer;
}

.online-course {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.remove-field-values {
    border-radius: 10px;
    color: white;
    border: none;
    margin: 18px 0px;
    padding: 15px 0px;
    width: 95%;
    text-decoration: none;
    cursor: pointer;
}

.search-btn-gestione-utenti {
    background-color: white;
    color: #0065f2;
    border: 1px solid #0065f2;
    border-radius: 5px;
    padding: 5px 80px;
    width: 100%;
    max-width: 320px;
}

.profile-checkboxes {
	height: 20px !important;
	width: 20px !important;
}