.sponsor-page {
    max-width: 100%;
}

.search-btn {
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    width: 100%;
    min-height: 35px;
    cursor: pointer;
    font-size: 20px;
}

.booked-list {
    padding: 10px;
    overflow-x: auto;
}

.booked-list.prenotazione-color-table .table-striped>thead>tr>th {
    background-color: #0065F2;
    color: white;
}

.booked-list.prenotazione-color-table {
    padding: 10px 0;
    max-width: initial;
    width: 100%;
}

.booked-list .table-striped>thead>tr>th {
    background-color: #007bff;
    color: white;
}

.booked-list .table-striped>thead>tr>th.company-name, .booked-list .table-striped>thead>tr>th.participants {
    width: 130px
}

.booked-list .table-striped>thead>tr>th:last-child {
    width: 90px;
}

.booked-list .table-striped td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    vertical-align: baseline;
}

.booked-list .expand-information b {
    margin-right: 20px;
}

.booked-list .table-striped tr.expanded td {
    white-space: pre-line;
}

.booked-list .table-striped>tbody>tr>td:last-child .delete {
    cursor: pointer;
}

.booked-list .table-striped>tbody>tr>td.number-of-participants {
    text-align: center;
}

/* .booked-list .table-striped>tbody>tr>td:last-child .delete svg path {
    fill: black
} */

.booked-list .table-striped>tbody>tr>td:last-child .edit {
    vertical-align: middle;
    margin-left: 10px;
    font-size: 25px;
    cursor: pointer;
}

.booked-list .table-striped .plus {
    font-size: 20px;
    background-color: #007bff;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.booked-list .table-striped>tbody>tr td {
    background-color: white;
}

.booked-list .table-striped>tbody>tr.highlighted td, .booked-list .table-striped>tbody>tr.highlighted {
    background-color: #F2F2F2;
}

.button-inside-info-table {
    border-radius: 10px;
    border: none;
    font-size: 18px;
    padding: 1px 9px;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    margin-right: 20px;
}

.img-inside-info-table {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.records-export {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.records-find {
    padding: 10px;
    font-size: 20px;
}

.export-table {
    width: 50px;
    height: 50px;
    cursor: pointer;
}