/* NORMAL LOGIN */

.modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    animation: fadeIn .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal.fadeOut {
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal .modal-box {
    background-color: white;
    min-width: 50%;
    max-width: 500px;
	min-width: 300px;
    color: black;
    border-radius: 5px;
    overflow: hidden;
    max-height: 90vh;
    overflow-y: auto;
    animation: scaleUp .7s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal .modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    min-height: 60px;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.modal .modal-header button {
    font-size: 15px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.modal .modal-body {
    box-sizing: border-box;
    padding: 15px 10px;
    min-height: 155px;
}

.modal-body .progress-bar {
    margin: 0 auto;
}

/* MODAL LOGIN */

.modal-login {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    animation: fadeIn .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
	backdrop-filter: blur(12px);
}

.modal-login.fadeOut {
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-login .modal-box-login {
    background-color: white;
    min-width: 90%;
    color: black;
    border-radius: 5px;
    overflow: hidden;
    max-height: 90vh;
    overflow-y: auto;
    animation: scaleUp .7s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-login .modal-header-login {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    min-height: 60px;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.modal-login .modal-header-login button {
    font-size: 15px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.modal-login .modal-body-login {
    box-sizing: border-box;
    /* padding: 15px 10px; */
    min-height: 155px;
}

.modal-body-login .progress-bar {
    margin: 0 auto;
}

.modal-box-login {
	width: 100%;
	min-width: initial !important;
	border-radius: 10px !important;
}


@media (max-width: 650px) {
  .modal .modal-box {
    min-width: 90% !important;
  }
  .modal-login .modal-box-login {
	  min-width: 90% !important;
  }
  .modal-login .modal-body-login {	  
    padding: 15px 15px !important;
  }
  .modal-login .modal-box-login {
    max-width: 500px;
  }
  .modal-box-login {
  max-width: 60vw !important;
  }
}

@keyframes scaleUp {
    /* 0% {
      transform:scale(.8) translateY(1000px);
      opacity:0;
    }
    100% {
      transform:scale(1) translateY(0px);
      opacity:1;
    } */
  }

  @keyframes fadeIn {
    0% {
      background:rgba(0,0,0,.0);
    }
    100% {
      background:rgba(0,0,0,.4);
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }