.homepage-wrapper {
	margin: 0 !important;
	padding: 0 !important;
}
.homepage-wrapper > div {
	margin: 0 !important;
}
.salto-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  width: 120px;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
}

.home-ciao {
  font-size: 56px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 15px;
}

.home-text-new {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 16px;
}

.salto-blue-button {
  width: 217px;
  height: 48px;
  font-size: 16px;
  border-radius: 8px;
  padding: 0;
  line-height: 22px;
  background-color: #246BF6;
  color: white;
  border: none;
}

.home-link {
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: rgba(0, 126, 242, 0.92);
}

.row-image-container img {
  width: 100%;
  max-width: 600px;
}

.entra-in-salto {
  display: block;
  cursor: pointer;
}

.scopri-di-piu {
  display: none;
  cursor: pointer;
}

.mobile-footer {
  display: none;
  width: 100%;
  order: 3;
}

@media (min-width: 768px) {
  .bg-home {
    background-image: url("../img/home/salone-home-new-2.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 440px;
    background-size: 70%;
  }


  .bg-shop {
    background-image: url("../img/home/shop.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 87%;
    height: 400px;
    width: 200px;
  }

}

@media (max-width: 768px) {
  .bg-home {
    background-image: url("../img/home/salone-home-new-2.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 280px;
    background-size: 295px;
  }

  .bg-shop {
    background-image: url("../img/home/shop.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    background-size: 350px 100%;
  }

}

@media (max-width: 768px) {
  .newsletter {
    font-weight: bold;
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .newsletter {
    font-weight: bold;
    font-size: 56px;
  }
}


@media (max-width: 767px) {

  .saltao-blue-button {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .entra-in-salto {
    display: none;
  }

  .scopri-di-piu {
    display: block;
  }

  .footer-desktop {
    display: none;
  }

  .mobile-footer {
    display: block;
  }
}

.no-padding {
	padding: 0 !important;
}