
.pagination {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.pagination .button-pagination {
    background-color: #E7E6E7;
    color: black;
    font-size: 15px;
    margin: 10px 2px;
    min-width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    
}

.pagination .button-pagination:hover {
    border: 2px solid #E7E6E7;
}
.pagination .button-pagination.active {
    background-color: #0065f2;
    color: white;
}