/* DESKTOP */

.dashboard-video-element {
	width: 100%;
	margin-bottom: 10px;
}

.dashboard-video-taste {
	font-size: 1.8rem;
	font-weight: bold;
	margin-bottom: 16px;
}

.dashboard-video-container {
	display: flex;
	height: 345px;
}

.dashboard-video {
	height: 341px;
	width: 616px;
}

.dashboard-video-right-side {
	padding-left: 16px;
	width: 300px;
}

.dashboard-video-title {
	font-size: 32px;
	font-weight: 600;
	line-height: 38px;
}

.dashboard-video-subtitle {
	margin-top: 8px;
	font-size: 18px;
	line-height: 18px;
	font-weight: 300;
}

.dashboard-video-navigation > p {
	margin-top: 14px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 0 !important;
}

/* MOBILE */

.dashboard-mobile-video-element {
	width: 100%;
}

.dashboard-mobile-video-taste {
	font-size: 1.8rem;
	font-weight: bold;
	margin-bottom: 3vh;
	margin-top: 3vh;
}

.dashboard-mobile-video-container {
	display: flex;
	flex-wrap: wrap;
}

.dashboard-mobile-video {
	width: 100%;
	height: 210px;
}

.dashboard-mobile-video-right-side {
	margin-top: 10px;
	width: 100%;
}

.dashboard-mobile-video-title {
	font-size: 1.8rem;
	line-height: 2.2rem;
	font-weight: 600;
}

.dashboard-mobile-video-subtitle {
	font-size: 1.2rem;
	font-weight: 300;
}

.dashboard-mobile-video-navigation > p {
	font-size: 1.2rem;
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 20px !important;
}