.sottotitolo-header-attestati {
	display: block !important;
}
@media (max-width:400px)  {
	.title {
		text-align: center;
	}
	.image > img {
		width: 100%;
	}
	.text-body {
		text-align: center;
	}
	.sottotitolo-header-attestati {
		text-align: center;
	}
	form {
		text-align: center;
	}
	.button-action {
		text-align: center;
	}
}