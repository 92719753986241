
.detailes-booked-event {
    width: 100%;
}
.detailes-booked-event .extra-info {
    width: 60%;
}

.detailes-booked-event .ciao-user {
    margin: 10px auto;
}

.detailes-booked-event .ciao-user .friends-list {
    padding: 10px;
    max-width: 850px;
    overflow-x: auto;
}

.blue-top-header {
	height: 40vh;
	width: 100vw;
	background-color: #007bff;
	margin-left: -25px;
	margin-bottom: 3vh;
}

.friends-list a {
    border: 2px solid #007bff;
    padding: 4px 10px;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

.detailes-booked-event .ciao-user .friends-list span svg {
    cursor: pointer;
}

.detailes-booked-event .ciao-user .add-friends {
    padding: 10px;
}

.detailes-booked-event .ciao-user .add-friends .plus-circle {
    padding: 10px;
}

.detailes-booked-event .ciao-user .add-friends .plus-circle svg {
    cursor: pointer;
}

.detailes-booked-event .ciao-user .add-friends .plus-circle span {
    padding: 10px;
    font-size: 15px;
}

.detailes-booked-event .join-remove-download button {
    color: white;
    background-color: #007bff;
    padding: 10px 45px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;
    margin-bottom: 10px;
}

.detailes-booked-event .join-remove-download {
    display: flex;
    text-align: center;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 370px;
}

.detailes-booked-event .join-remove-download p{
    white-space: nowrap;
    cursor: pointer;
    text-decoration:underline;
    margin-left: 5px;
}

.greeting-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
}
.greeting-container > svg {
	width: 25%;
	height: 50%;
	max-height: 25px;
}
.greeting-container > p {
	width: 70%;
	font-weight: 500;
	margin-bottom: 0;
}
.event-description {
	margin-top: 3vh;
}

.actions-container-booking-details {
	width: 100%;
	display: flex;
	margin-top: 50px;
	justify-content: space-between;
}
.add-friend {
	color: white !important;
    background-color: #007bff;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none !important;
	text-align: center;
	display: block;
	border: 5px solid #007bff;
	width: 47.5%;
}
.download-pdf {	
	color: #007bff !important;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
	display: block;
	border: 5px solid #007bff;
	text-align: center;
	width: 47.5%;
}
.download-pdf:hover {
	text-decoration: none;
}

@media (max-width: 570px) {
	.detailes-booked-event {
		padding: 0;
	}
	.event-box {
		padding: 0;
		padding-right: 35%;
	}
	.actions-container-booking-details {
		flex-wrap: wrap;
	}
	.add-friend {
		margin-bottom: 2vh;
	}
	.add-friend, .download-pdf {
		width: 100%;
	}
}