.userprofile-form {
	text-align: left !important;
}
.title-row {
	margin-bottom: 5vh;
}
.navigation-link {
	color: #007bff;
	text-decoration: none;
	cursor: pointer;
	font-family: "ITCFranklinGothicStd";
	font-size: 1.2rem;
	font-weight: 400;
}
.navigation-link:hover {
	text-decoration: underline;
}

.profile-newsletter-mobile {
	padding-right: 0 !important;
}

/* input:checked {
	accent-color: #99c1fa;
} */