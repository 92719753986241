.dashboard-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.dashboard {
    position: relative;
    padding: 50px 15px;
    margin-bottom: 50px;
    margin-top: 50px;
    justify-content: space-evenly;
    max-height: 700px
}

.main-padding-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cards-admin-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 243px));
    justify-content: center;
}

.dashboard .card-admin-ashboard {
    box-sizing: border-box;
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    max-width: 250px;
}

.dashboard .card-admin-ashboard a p {
    text-align: center;
    align-items: center;
    font-size: 25px;
}

.dashboard .card-admin-ashboard .card-img-admin {
    cursor: pointer;
    position: relative;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 3px 10px #1d66aa;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    min-height: 180px;
    justify-content: center;
}

.dashboard .card-admin-ashboard .card-img-admin img {
    width: 75px;
    margin: 16px 0 16px 0;
}

/* .dashboard .card-admin-ashboard .card-img-admin:hover:after {
    display: block;
}

.dashboard .card-admin-ashboard .card-img-admin:after {
    content: "";
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    transform: translateY(-52%);
    background: rgba(190, 209, 236, 0.65);
} */