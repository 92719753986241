.attestato_titolo {
    font-size: 2rem;
    font-weight: 600;
}

.bibliografia_subtitolo {
    font-weight: bold;
    font-style: italic;
}

.attestato_link_container {
    padding-left: 20px;
}

.attestato_data {
    display: list-item;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.full_attestato_password_container>label {
    display: block;
    width: 40%;
}

.full_attestato_verify {
    margin-bottom: 20px;
}