.close-btn-container {
    display: flex;
    justify-content: flex-end;
	width: 100%;
}

.close-btn-container button {
    color: #007bff;
    background-color: transparent;
    border: none;
    font-size: 20px;
	outline: none !important;
}

.presentation-container {
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.presentation-header {
	width: 100%;
	height: fit-content;
	margin: 0 auto 2%;
}
.presentation-header > p {
	width: 60%;
	margin: 0 auto;
	font-size: 1.4rem;
	font-weight: bold;
	text-align: center;
}

.presentation-body {
	height: fit-content;
	width: 70%;
	text-align: center;
	margin: 0 auto;
}
.presentation-body > div {
	height: 100%;
}
.upper-title {
	margin: 5% auto 2%;
	text-align: center;
}
.upper-title > p {	
	font-size: 1.2rem !important;
}
.lower-title {
	margin: 0 auto 2%;
	text-align: center;
}
.presentation-body > div > p {
	font-weight: 700;
	font-size: 1rem;
}

.event-card-logo {
	height: 12vh;
}


.risen-up-body {
}
.logo-text-row {
	display: flex;
	height: 30%;
}
.logo-row {
	align-self: center;
	width: 9%;
	max-height: 25px;
	filter: invert(31%) sepia(99%) saturate(4433%) hue-rotate(207deg) brightness(97%) contrast(101%);
	height: -webkit-fill-available;
}
.logo-text-row > p {	
	font-size: 1rem;
	text-align: left;
	padding-left: 5%;
	margin: 0 !important;
	align-self: center;
}
.logo-logo-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 4%;
}
.two-logo-row {
	width: 50%;
}
.two-logo-row > img {
	width: 80%;
	height: fit-content;
	-webkit-box-shadow: 8px 9px 10px -6px rgba(0,0,0,0.44); 
	box-shadow: 8px 9px 10px -6px rgba(0,0,0,0.44);
}
.one-logo-row {
	width: 50%;
	margin: 5% auto 0;
}
.one-logo-row > img {
	width: 80%;
	height: fit-content;
	-webkit-box-shadow: 8px 9px 10px -6px rgba(0,0,0,0.44); 
	box-shadow: 8px 9px 10px -6px rgba(0,0,0,0.44);
}

.presentation-footer {
	width: 100%;
	height: 7%;
	align-self: end;
}
.presentation-footer > div {
	width: 30%;
	display: flex;
	margin: 0 auto;
}
.footer-text {
	width: 50%;
	line-height: 35px;
	text-align: end;
	font-size: 0.95rem;
	margin-bottom: 0;
}
.presentation-navigation-container {
	justify-content: space-between;
}
.back-icon {
	transform: rotate(180deg);
	width: auto;
	height: 20px;
	cursor: pointer;
	align-self: center;
	margin-left: 10px;
}
.forward-icon {
	width: auto;
	height: 20px;
	cursor: pointer;
	align-self: center;
	margin-left: 10px;
}

.footer-button {
	width: 40% !important;
	height: 4vh;
	border: none;
}
.go-back-button {
	width: 100%;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
}
.go-back-button:focus, .go-back-button:active {
	border: none;
}

.pagination {
	height: 2%;
	align-self: end;
	margin: 5% auto;
}
.pagination-image {
	max-width: 75px;
}

@media (max-width: 650px) {
	.presentation-header {
		margin: 5% auto 2% !important;
		height: 15% !important;
	}
	.presentation-header > p {
		font-size: 1rem;
		width: 90% !important;
	}
	.two-logo-row > img {
		max-width: 100px !important;
		max-height: 50px !important;
	}
	.one-logo-row > img {
		max-width: 100px !important;
		max-height: 50px !important;
	}
	.presentation-body {
		width: 80% !important;
	}
	.presentation-body > div > p {
		font-size: 0.9rem !important;
	}
	.logo-text-row > p {
		font-size: 0.7rem !important;
	}
	.presentation-footer > div {
		width: 50% !important;
	}
	.pagination > img {
		max-height: 100% !important;
		max-width: 100% !important;
	}
}

@media (max-width: 380px) {
	.modal-box-bookings {
		height: 82% !important;
	}
}