.activebar-container {
    margin: 0px;
    display: block;
    position: fixed;
    top: 10px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 9999;
    overflow: hidden;
    overflow-x: hidden;
    background-color: #9eafd3 ;
    color: black;
}

.row.space {
    padding: 20px 20px;
}

.row.space .p {
    margin-top: 20px;
    color: black;
    
}

.row.space .buttons-container {
    margin-top: 40px;
}

.row.space .links a {
    color: white;
    /* text-decoration: none; */
}