
#root div.modal-cookie {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    max-width: 100%; 
    padding: 0;
    flex-direction: row;
}

.modal-cookie .modal-cookie-wrapper {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-cookie.fadeOut {
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-cookie .modal-box {
    background-color: #256bf6;
    min-width: 70%;
    color: black;
    border-radius: 5px;
    overflow: hidden;
    animation: scaleUp .7s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    max-width: calc(100% - 40px);
}

.modal-cookie .modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    min-height: 60px;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.modal-cookie .modal-header button {
    font-size: 15px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.modal-cookie h3 {
  color: white;
}

.modal-cookie p {
  color: white;
}

.modal-cookie ul {
  color: white;
}

.modal-cookie .modal-body {
    box-sizing: border-box;
    padding: 15px 10px;
    color: white;
}

.accept-buuton-cookies {
  background-color: white;
  color: #256bf6;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
  outline: none;
}

.accept-buuton-cookies:focus{
  outline: none;
}

.modal-body .progress-bar {
    margin: 0 auto;
}

@keyframes scaleUp {
    0% {
      transform:scale(.8) translateY(1000px);
      opacity:0;
    }
    100% {
      transform:scale(1) translateY(0px);
      opacity:1;
    }
  }

  @keyframes fadeIn {
    0% {
      background:rgba(0,0,0,.0);
    }
    100% {
      background:rgba(0,0,0,.4);
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }