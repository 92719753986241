.container-multiple-select {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    white-space: nowrap;
}

.container-multiple-select .preview-multi-select {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.container-multiple-select .text-preview {
    flex-grow: 1;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
}

.container-multiple-select .options {
    position: absolute;
    top: 100%;
    z-index: 100;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid black;
    background-color: white;
    max-height: 150px;
    overflow-y: auto;
}

.container-multiple-select .options .option {
    padding: 2px 10px;
    cursor: pointer;
    font-size: 20px;
}
.container-multiple-select .options .option.selected {
    background-color: #0065F2;
    margin-bottom: 1px;
    color: white;
}

.container-multiple-select .options .option:hover {
    background-color: #0065F2;
    color: white;
}

.container-multiple-select .select-day {
    display: flex;
    border: none;
    background-color: #0065f2;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: white;
    border-radius: 20px;
    padding: 5px 10px;
    align-items: center;
}

.container-multiple-select .select-day .text-preview {
    font-size: 15px;
    font-weight: 400;
}

.container-multiple-select .select-day svg {
    z-index: 1;
    width: 16px;
    height: 16px;
}

.container-multiple-select .select-day svg path {
    fill: white;
}

.container-multiple-select .select-day-prenotazioni, .container-multiple-select .select-hour-prenotazioni  {
    display: flex;
    border: none;
    background-color: #0065f2;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: white;
    border-radius: 20px;
    padding: 5px 10px;
    align-items: center;
}

.container-multiple-select .select-day-prenotazioni .text-preview, .container-multiple-select .select-hour-prenotazioni .text-preview {
    font-size: 15px;
    font-weight: 400;
}

.container-multiple-select .select-day-prenotazioni svg, .container-multiple-select .select-hour-prenotazioni svg {
    z-index: 1;
    width: 16px;
    height: 16px;
}

.container-multiple-select .select-day-prenotazioni svg path, .container-multiple-select .select-hour-prenotazioni svg path {
    fill: white;
}

/* SHOWING SELECT  TIME */
.showing-select-modal-container {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	align-content: flex-start;
}
.selected-option {
	color: #0065F2;
	font-size: 24px;
	line-height: 28px;
}

.showing-select-header {
	height: 40px;
	margin-left: auto;
}
.showing-select-title {
	font-size: 18px;
	line-height: 28px;
	margin-bottom: 24px;
    white-space: break-spaces;
}
.showing-select-cancel {
	border: 2px solid #0065F2;
	background-color: white;
	color: #0065F2;
	width: 116px;
	height: 40px;
	border-radius: 8px;
	margin: 0 72px 24px;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
}
.showing-select-cancel:focus {
	outline-color: 2px solid #0065F2 !important;
	color: white;
	background-color: #0065F2;
}

.separe-container {
	display: flex;
	align-content: center;
	margin-bottom: 20px;
	justify-content: center;
}
.separe-container > * {
	display: inline-block;
	margin-bottom: 0;
	line-height: 18px;
}
.separe-container > div {	
	position: relative;
	height: 1px;
	width: 83px;
	background-color: black;
	top: 9px
}
.separe-container > p {
	margin: 0 16px;
	font-size: 14px;
}

.showing-select-body {
	width: 100%;
	text-align: center
}
.showing-select-options {
	margin-bottom: 16px;
}

.showing-select-timepicker {
	margin-bottom: 36px !important;
}

.showing-select-footer {
	margin-top: 0;
}
.showing-select-actions {	
	display: flex;
}
.showing-select-close {
	border: none;
	background-color: white;
	color: #0065F2;
	width: 119px;
	height: 40px;
	border-radius: 8px;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	justify-self: left;
	margin-left: 16px;
}
.showing-select-close:active {
	outline: none;
}
.showing-select-confirm {
	border: 2px solid #0065F2 !important;
	color: white;
	background-color: #0065F2;
	width: 116px;
	height: 40px;
	border-radius: 8px;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	margin-right: 16px;
	align-self: flex-end;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
	color: black !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
	color: black !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
	border-color: black !important;
}



/* TIME INPUT */
.showing-select-time-input-container {
	margin: 0 8px 16px;
	width: 100%;
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
}
.showing-select-time-title {
	text-align: left;
	font-size: 16px;
	color: #0065F2;
	border-bottom: 2px solid #0065F2;
}

.showing-select-time-input-row {
	display: flex;
	width: 100%;
	margin-bottom: 16px;
}
.showing-select-time-input-row > input {	
	width: 75px;
	height: 75px;
	padding: 0;
	border: 2px solid #0065F2;
	border-radius: 8px;
	font-size: 36px;
	text-align: center;
	-moz-appearance: textfield;
}
.showing-select-time-input-row > input::-webkit-outer-spin-button,
.showing-select-time-input-row > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.showing-select-time-input-row > p {
	font-size: 30px;
	margin: 0 16px;
	font-weight: bold;
    align-self: center;
}

.showing-select-time-ampm {
	display: flex;
	flex-wrap: wrap;
	margin-left: 16px;
	width: 35px;
	justify-content: space-between;
	align-content: space-between;
}
.showing-select-am,
.showing-select-pm {
	width: 100%;
	height: 35px;
	font-size: 12px;
	border-radius: 4px;
	border-color: #0065F2;
	text-align: center;
	padding: 2px;
}

.select-interval {
	color: white;
	background-color: #0065F2;
}