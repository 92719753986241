

.button-message {
    max-width: 50%;
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    color: #246BF6;
    border-color: #246BF6;
    box-sizing: border-box;
    padding: 10px;
}

.button-message.back {
    width: 50%;
    border-radius: 10px;
    background-color: white;
    color: #246BF6;
    border-color: #246BF6;
    box-sizing: border-box;
    padding: 10px;
}

.button-message:hover {
    background-color: #246BF6;
    text-decoration: none;
    color: white;
}

.buttons-styled {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

