/******************* bootstrap override *****************/

.modal-content {
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

.modal-body {
    padding: 0 !important;
}

/********************************************************/

input {
    width: 100%;
    padding-left: 10px;
    height: 35px;
    border: none;
    background-color: rgba(127, 127, 127, 0.2);
    border-radius: 10px;
}

textarea {
    padding-left: 10px;
    height: 100px;
    border: none;
    background-color: rgba(127, 127, 127, 0.2);
    border-radius: 10px;
}

input[type="checkbox"] {
    accent-color: #99c1fa;
    width: auto;
    height: auto;
    border: none;
    background-color: rgba(127, 127, 127, 0.2);
}

select {
    width: 100%;
    padding-left: 10px;
    height: 35px;
    border: none;
    background-color: rgba(127, 127, 127, 0.2);
    border-radius: 10px;
    /* color: rgba(127, 127, 127, 1); */
}

.loading {
    display: flex;
    justify-content: center;
    height: 150px;
    align-items: center;
    margin-bottom: -30px;
    background-color: lightgray;
    opacity: 70%;
}

.loading svg {
    width: 100px;
    height: 100px;
}

.loading.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading.fixed svg {
    width: 200px;
    height: 200px;
}

.error-box {
    margin-top: 10px;
    color: #dc3545;
}

@media (min-width: 770px) {
    .login-img {
        background-image: url('../img/home/login.jpg');
        background-size: 260px 500px;
        background-position: center;
        background-repeat: no-repeat;
        width: 260px;
        height: 500px
    }

    .signup-img {
        background-image: url('../img/home/login.jpg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: inherit
    }

    .bg-password {
        background-image: url("../img/home/password.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}