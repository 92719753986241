.container-event-component {
    border-top: 2px solid #165BE2;
    margin-top: 30px;
    display: flex;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.striped-responsive {
    display: flex;
}

.striped-responsive .data-title-place {
    flex-grow: 1;
}

.event-section-one {
    margin-top: 10px;
    max-width: 800px;
    flex-grow: 1;
}

.event-section-one .meeting-with p {
    margin-bottom: -5px;
}

.event-section-one .meeting-with h4 {
    margin-top: 5px;
    margin-bottom: 20px;
}

.event-section-one .a-cura-di p {
    margin-bottom: -5px;
}

.event-section-one .a-cura-di h5 {
    margin-top: 5px;
    margin-bottom: 20px;
}

.event-section-one .event-description p.has-more {
    cursor: pointer;
}

.event-description p.has-more span {
    display: inline-block;
    color: #007bff;
}

.event-section-two {
    margin-top: 10px;
    white-space: nowrap;
    max-width: 300px;
}

.responsive {
    display: none;
}

.event-section-two .book-btn,
.event-section-two .booked-btn {
    width: 100%;
    margin: 15px 0;
}

.book-btn a {
    background-color: white;
    border: 4px solid #0065F2;
    border-radius: 12px;
    font-size: 18px;
    padding: 6px;
    min-width: 150px;
    color: #0065F2;
    text-decoration: none;
}

.book-btn button {
    background-color: white;
    border: 4px solid #878d95;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 600;
    padding: 6px;
    min-width: 150px;
    color: #59616b;
    text-decoration: none;
    max-width: 100%;
    white-space: pre-line;
    /* cursor: not-allowed; */
}

.booked-btn a {
    background-color: #0065F2;
    border: 4px solid #0065F2;
    border-radius: 12px;
    font-size: 18px;
    padding: 6px;
    color: white;
    text-decoration: none;
    min-width: 150px;
    max-width: 100%;
    white-space: pre-line;
}

.book-btn .event-book-btn.sold-out {
    color: red;
    border-color: red;
}

.event-section-one .message-icon {
    display: flex;
    align-items: center;
    margin-top: 101px;
}

.event-section-one .message-icon svg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 26px;
}

.categories p {
    text-decoration-line: underline;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media(max-width: 540px) {
    .book-btn a {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .book-btn button {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .booked-btn a {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .booked-btn button {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .responsive {
        display: block;
        margin: 10px auto;
    }

    .responsive .event-book-btn.highlighted {
        color: white;
        background-color: #007bff;
        border-color: #007bff;
    }

    .responsive a {
        display: block;
        background-color: white;
        border: 4px solid #0065F2;
        border-radius: 12px;
        font-size: 18px;
        padding: 6px;
        width: 55%;
        color: #0065F2;
        text-decoration: none;
    }

    .responsive button {
        display: block;
        background-color: white;
        border: 4px solid #878d95;
        border-radius: 12px;
        font-size: 18px;
        padding: 6px;
        width: 55%;
        color: #878d95;
        text-decoration: none;
        cursor: not-allowed;
    }

    .responsive .event-book-btn.sold-out {
        color: red;
        border-color: red;
    }
}

.event-subtitle>* {
    font-weight: 400 !important;
}

/* Stile nuovi messaggi di avviso */
.not-bookable-event {
    font-weight: bold;
    color: red;
}

.not-full-event {
    font-weight: bold;
    color: #246BF6;
}