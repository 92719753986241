.moduli-prenotazione {
    margin-top: 20px;
    align-items: start;
    /* justify-content: center; */
}

.iscrizioni-effettuate, .corsi-disponibili-moduli {
    padding: 10px;
    background: #057DF6;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 14%);
    border-radius: 8px;
    margin: 0 15px;
}

.corsi-disponibili {
    padding: 10px;
    background: #057DF6;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 14%);
    border-radius: 8px;
    margin: 0 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.download-delete, .booking-course {
    padding: 5px 10px;
    border-radius: 10px;
    background: #057DF6;
    color: white;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid #057DF6;
}

.download-delete:hover, .booking-course:hover {
    background-color: white;
    color: #057DF6;
    border: 1px solid #057DF6;
    text-decoration: none;
}