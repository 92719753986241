.add-person-booking {
    display: flex;
    width: 100%;
}

.add-person-booking .highlited-part {
    flex-grow: 1;
    background: #007bff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}
.add-person-booking .highlited-part .svg-container svg {
    width: 100%;
    height: 100%;
}

.add-person-booking .add-contents {
    padding: 15px;
    width: 100%;
}

.add-person-booking .add-contents .close-btn-container {
    margin: 5px;
    display: flex;
    justify-content: flex-end;
}

.add-person-booking .add-contents .close-btn-container button {
    color: #007bff;
    background-color: transparent;
    border: none;
    font-size: 20px;
}

.add-contents .elimina-info p {
    font-size: 17px;
    font-weight: 600;
}

.add-contents .add-form p {
    font-size: 17px;
}

.add-form {
	margin-bottom: 10%;
}

.input-container {
	margin: 5% 0 5% 0;
}

.add-contents .add-btn {
    margin: 10px auto;
    width: 100%;
}

.add-contents .add-btn button, .add-contents .back-btn button {
    color: white;
    background-color: #007bff;
    padding: 10px 50px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;
    width: 100%;
}

.add-contents .back-btn {
    width: 100%;
}

@media(max-width: 500px){
    .add-person-booking .highlited-part {
        flex-grow: 0;
    }
    .add-person-booking .add-contents .close-btn-container {
        margin: 0;
    }
    .add-person-booking .add-contents {
        width: 100%;
    }
}