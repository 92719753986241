.auth-btn {
	width: 120px;
	height: 100%;
}

.signup-login {
	border-radius: 10px;
    background-color: #246BF6;
    color: white;
    border-color: transparent;
}

.header-logo-container {
	padding: 0 !important;
}

.logo-salone {
	height: 200px;
	width: 330px;
}

.omino {
	width: 30px;
	height: 30px;
	margin-top: 3%;
	margin-left: 70%;
}

.profile {
	font-size: 24px;
	font-weight: 500;
	color: rgb(197, 197, 199);
	margin-top: 3.2%;
}

.profile-pic {
	line-height: 37px;
}

.profile-picspan {
	color: rgba(0, 0, 0, 0.3);
	text-decoration: none;
	margin-left: 10px;
}

.profile-pic:hover {
	text-decoration: none;
}

/* MENU NAVIGAZIONE */
/* .navigation-container {
} */

.profile-modal-container {
	position: absolute;
	top: 45px;
	padding: 10px;
	box-shadow: 0px 3px 10px lightgrey;
	border-radius: 10px;
	background-color: white;
	z-index: 100;
}

.profile-modal-container > p, .profile-modal-container > * > p {
	padding-top: 2vh;
	border-top: 2px solid #007bff;
	padding-bottom: 2vh;
	margin-bottom: 0;
}
.profile-modal-container > p:hover, .profile-modal-container > a:hover {
	text-decoration: none !important;
}

.profile-modal-container > p:nth-child(3) {
	border-bottom: 2px solid #007bff;
	padding-bottom: 2vh;
}
.custom-modal.fadeOut {
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards !important;
}

@media (max-width: 810px) {
	.un-progetto {
		display: none;
	}
	.salone-del-libro {
		display: none;
	}
}

@media (max-width: 605px) {
}

@media (min-width: 605px) {
	.profile-pic {
		height: 37px;
		width: 24px;
	}
	.auth-btn {
		width: 110px;
		height: 40px;
	}
	.profile-text {
		color: #dadada;
		text-decoration: none;
		font-size: 18px;
		padding-top: 6px;
	}
}

@media (max-width: 605px) {
	.profile-logo-container-mobile {
		padding-right: 9px;
	}

	.profile-pic {
		height: 37px;
		width: 24px;
	}

	.auth-btn {
		width: 90px;
		height: 35px;
	}
	.profile-pic {
		height: 37px;
		width: 24px;
	}
	.profile-text {
		color: #dadada;
		text-decoration: none;
		font-size: 14px;
		padding-top: 6px;
	}
}
