
/* HEADER */

.modal-box-login {
	max-width: 830px;
}

.modal-login-container {
	height: 500px;
	max-width: 800px;
	font-size: 0.95rem;
	letter-spacing: 0.8px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.modal-login-right-container {
	width: 500px;
	height: 500px;
	background-color: #e5e5f7;
	opacity: 0.8;
	background: repeating-linear-gradient( -45deg, #8e93ff, #c3c6ff 2px, #e5e5f7 2px, #e5e5f7 15px );
	margin-right: 15px;
}

.modal-login-page-1, .modal-login-page-2, .modal-login-page-3 {
	width: 285px;
}

.page-1-close-btn {
	margin-bottom: 5% !important;
}
.modal-login-top-logo {
	height: 10%;
	text-align: center;
	margin-bottom: 6%;
}
.modal-login-top-logo > img {
	height: 85%;
	width: auto;
	margin-top: -5px;
}

/* FORM E INPUTS */

.modal-login-form {
}
.modal-login-input-label {
	margin-bottom: 5%;
	text-align: left;
	font-weight: 100;
	cursor: text;
}
.modal-login-input {
	margin-bottom: 5%;
	background-color: white !important;
	border-bottom: 1px solid black;
	border-radius: 0 !important;
    outline: none;
	font-weight: 100;
}
.modal-login-input:focus, .modal-login-input:active {
	background-color: white !important;
	border-bottom: 1px solid black;
    outline: none;
}

/* RICORDAMI */

.modal-login-label-ricordami {
	font-weight: 100;
	color: rgb(150, 148, 148);
	display: flex;
    align-items: center;
}
.modal-login-checkbox-ricordami {
	margin-right: 5px;
}

/* RESET PASSWORD*/

.modal-login-reset-password-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.modal-login-reset-password {
	font-weight: 100;
	cursor: pointer;
}

/* ACTIONS */

.modal-login-action-container {
	margin-top: 8%;
}
.modal-login-button {
	font-size: 1.05rem;
	font-weight: 100;
    color: white;
    background-color: #007bff;
	border: none;
    border-radius: 10px;
    width: 100%;
    display: block;
    text-align: center;
    margin: 0 auto;
	padding: 4px 0;

	-webkit-box-shadow: 0px 3px 15px -2px rgba(0,0,0,0.18); 
	box-shadow: 0px 3px 15px -2px rgba(0,0,0,0.18);
}
.modal-login-button:focus {
	outline: none !important;
}
.modal-login-button:active {	
	-webkit-box-shadow: inset 0px 3px 15px -2px rgba(0,0,0,0.2); 
	box-shadow: inset 0px 3px 15px -2px rgba(0,0,0,0.2);
}
.modal-login-register-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
    align-items: baseline;
}
.modal-login-register-container > p {
	margin-top: 3%;
	font-size: 0.85rem;
	text-align: center;
	display: inline-block;
	font-weight: 100;
	color: rgb(150, 148, 148);
}
.modal-login-register-link {
	margin-left: 5%;
	cursor: pointer;
}

/* CONFIRMATION EMAIL */
.close-btn-container {
    display: flex;
    justify-content: flex-end;
}

.close-btn-container button {
    color: #007bff;
    background-color: transparent;
    border: none;
    font-size: 20px;
	outline: none !important;
}

/* CONFIRMATION EMAIL HEADER */
.modal-login-confirmation-header {
	display: flex;
	align-items: center;
}
.modal-login-confirmation-header-image-container {
	width: 35%;
	text-align: center;
}
.modal-login-confirmation-header-image {
	width: auto;
	height: 10vh;
}
.modal-login-confirmation-header-text {
	width: 80%;
	margin: 0 !important;
	font-weight: 500;
	font-size: 1.5rem;
}

/* CONFIRMATION EMAIL INPUT*/

.modal-login-confirmation-resend {
	margin-top: 0%;
}
.modal-login-confirmation-resend-label {
	font-size: 1rem;
	font-weight: 100;
}
.modal-login-confirmation-resend-input {
	font-size: 1rem;
}

/* CONFIRMATION STATUS*/
.modal-login-confirmation-status {
	margin-top: 5%;
	text-align: center;
}

.confirmation-status {
	color: chartreuse;
}

/* ERROR MESSAGE*/

.modal-login-error {
	margin-top: 5%;
	text-align: center;
}

.error-status {
	color: red;
}

/* CONFIRMATION ACTIONS */

.modal-login-confirmation-actions {
	margin-top: 7%;
	text-align: center;
}
.modal-login-confirmation-actions-button {
	width: 100%;
	color: white;
	background-color: #057df6;
	border: none;
	font-weight: 400;
	border-radius: 10px;
	font-size: 1.1rem;
	padding: 10px 0;
}
.modal-login-confirmation-actions-text {
	margin-top: 3%;
	cursor: pointer;
}

/* LOADING */
.modal-login-page-3 {

}

/* LOADING HEADER */
.modal-login-loading-header {
	text-align: center;
}
.modal-login-loading-header-image {

}

/* LOADING BODY*/

.modal-login-loading-body {
	width: 90%;
}
.modal-login-loading-body-title {
	font-size: 2.6rem;
	line-height: 2.8rem;
	font-weight: 600;
}
.modal-login-loading-body-text {
	font-size: 1.2rem;
}

.modal-login-text-email-confirm {
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
}

/* LOADING ACTIONS */

.modal-login-loading-action {
	text-align: center;
}
.modal-login-loading-button {
	width: 100%;
	color: white;
	background-color: #057df6;
	border: none;
	font-weight: 400;
	border-radius: 10px;
	font-size: 1.1rem;
	padding: 10px 0;
}

@media (max-width: 650px) {
	.modal-login-loading-body-title {
		font-size: 2rem;
		line-height: 2.2rem;
		font-weight: 600;
	}
	.modal-login-form {
		text-align: left;
	}
	.modal-login-loading-body-text {
		font-size: 1rem;
	}
	.modal-login-loading-button {
		margin-bottom: 10%;
	}
  }