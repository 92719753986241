
.history-link {
	color: #007bff;
	font-weight: 500;
	cursor: pointer;
}
.descriptor {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
}
.descriptor > svg {
	width: 25%;
	height: 50%;
	max-height: 25px;
}
.descriptor > p {
	width: 70%;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
	line-height: 22px;
}
.add-people > p {
	margin-top: 5vh;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}
.add-actions {
	margin-top: 3vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}
.add-icon-max-reached {
	transform: rotate(45deg);
	filter: invert(11%) sepia(99%) saturate(7456%) hue-rotate(359deg) brightness(92%) contrast(119%);
}
.add-actions > img {
	width: 12.5%;
	max-height: 54px;
	filter: invert(31%) sepia(99%) saturate(4433%) hue-rotate(207deg) brightness(97%) contrast(101%);
}
.add-actions > p {
	width: 70%;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}
.remove-people > p {
	margin-top: 3vh;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
}
.person {
	display: block;
}
.person > label {
	font-size: 1.3rem;
	font-weight: 500;
}
.person > label > input {
	margin-right: 10px;
	border-radius: 0;
	height: 20px;
	width: 20px;
	background-color: #007bff;
}

.actions-container-add-booking {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
.remove-selected {
	color: white !important;
    background-color: #007bff;
    padding: 10px 50px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none !important;
	display: block;
	border: 5px solid #007bff;
	text-align: center;
	width: 47.5%;
	margin-bottom: 2vh;
}
.select-all {	
	color: #007bff !important;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
	display: block;
	border: 5px solid #007bff;
	text-align: center;
	width: 47.5%;
	margin-bottom: 2vh;
}
.delete-booking-text {
	margin: 0;
	text-align: left;
	width: 90%;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}

/* DESKTOP */

.descriptor {
	width: 50%;
}
.add-people > p {
	margin-top: 3vh;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}
.add-actions {
	width: 50%;
}
.remove-people > p {
	text-align: left;
	margin-bottom: 2vh;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}
.remove-people {
	margin-top: 36px;
	margin-bottom: 36px;
}

@media (max-width: 650px){
	.descriptor {
		width: 100%;
		margin-top: 5vh;
	}
	.add-people > p {
		margin-top: 5vh !important;
		font-size: 0.9rem;
	}
	.add-actions {
		width: 100%;
	}
	.remove-people > p {
		font-size: 0.9rem;
		text-align: center;
	}
	.remove-selected, .select-all {
		width: 100%;
	}
}