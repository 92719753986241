.delete-booking {
    display: flex;
    width: 100%;
}

.delete-booking .highlited-part {
    flex-grow: 1;
    background: #007bff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}
.delete-booking .highlited-part .svg-container svg {
    width: 100%;
    height: 100%;
}

.delete-booking .delete-contents {
    padding: 15px;
    width: 100%;
}

.delete-booking .delete-contents .close-btn-container {
    margin: 5px;
    display: flex;
    justify-content: flex-end;
}

.delete-booking .delete-contents .close-btn-container button {
    color: #007bff;
    background-color: transparent;
    border: none;
    font-size: 20px;
}

.delete-contents .elimina-info p {
    font-size: 17px;
    font-weight: 600;
}

.delete-contents .text-question p {
    font-size: 17px;
}

.delete-contents .elimina-btn {
    margin: 10px auto;
    width: 100%;
}

.delete-contents .elimina-btn button, .delete-contents .back-btn button {
    color: white;
    background-color: #007bff;
    padding: 10px 50px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;
    width: 100%;
}

.delete-contents .back-btn {
    width: 100%;
}
.elimina-btn > button {
	border: 4px solid #007bff !important;
}
.back-btn > button {
	padding-right: 0px !important;
	padding-left: 0px !important;
	width: 100%;
	color: #007bff !important;
	background-color: white !important;
	border: 4px solid #007bff !important;
}

.title-success > p {
	font-size: 1.5rem;
	font-weight: 600;
}

.success-body > p {
	font-weight: 500;
}

.success-back > button {
	width: 100%;
    padding: 10px 30px;
	color: #007bff !important;
	background-color: white !important;
	border: 4px solid #007bff !important;
    border-radius: 10px;
}

@media(max-width: 500px){
    .delete-booking .highlited-part {
        flex-grow: 0;
    }
    .delete-booking .delete-contents .close-btn-container {
        margin: 0;
    }
    .delete-booking .delete-contents {
        width: 100%;
    }
}