.nav-bar {
    display: flex;
    align-items: center;
}

.nav-bar .back-to-previous-page {
    padding: 10px;
    border: 1px solid #057DF6;
    border-radius: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.nav-bar h2 {
    padding: 10px;
    color: #057DF6;
}