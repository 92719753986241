.container-error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-error-message svg {
    height: 200px;
    width: 200px;
    margin: 20px;
}

.container-success-message {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
}

.container-tick-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
	max-width: 370px;
}

.container-tick-info img {
    width: 100px;
    height: 100px;
    margin: 10px auto;
}

.access-table {
    align-self: stretch;
}