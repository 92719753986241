/* FERMA LE CARD DALL'ANDARE A CAPO CAUSA MARGINE*/
.dashboard-row {
	flex-wrap: nowrap;
	margin-left: 0px;
	margin-right: -16px;
}


/* STILI GENERICI APPLICATI AD OGNI CARD*/
.dashboard-card-styler {
	display: block;
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.14);
	border-radius: 8px;
	width: 100%;
	height: 100%;
}

.dashboard-card-container {
	box-shadow: none;
	border-radius: none;
	margin: 0 0 16px 0;
	padding: 0 16px 0 0;
}

.dashboard-card-container>* {
	width: 100%;
}

.dashboard-card-container>*:hover {
	text-decoration: none;
}

.dashboard-card-container>*>* {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-content: center;
	text-align: center;
	width: 100%;
	height: auto;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
}

.dashboard-card-title {
	width: 100%;
	font-weight: bold;
}

.dashboard-card-subtitle {
	width: 100%;
}

/* QUI SOTTO SONO DEFINITE LE DIMENSIONI PER LE VARIE CARD SIZE*/

/* CARD SIZE 12 */

.card-size-12>*>* {
	width: 60%;
}

.card-icon-12 {
	height: 150px;
}

.dashboard-card-title-12 {
	font-weight: 600;
	font-size: 40px;
	line-height: 48px;
}

.dashboard-card-subtitle-12 {
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
}

/* CARD SIZE 11 */

.card-size-11>*>* {
	width: 60%;
}

.card-icon-11 {
	height: 25vh;
}

.dashboard-card-title-11 {
	font-size: 2.2rem;
}

.dashboard-card-subtitle-11 {
	font-size: 1.4rem;
}

/* CARD SIZE 10 */

.card-size-10>*>* {
	width: 60%;
}

.card-icon-10 {
	height: 25vh;
}

.dashboard-card-title-10 {
	font-size: 2.2rem;
}

.dashboard-card-subtitle-10 {
	font-size: 1.4rem;
}

/* CARD SIZE 9 */

.card-size-9>*>* {
	width: 60%;
}

.card-icon-9 {
	height: 18vh;
}

.dashboard-card-title-9 {
	font-size: 1.8rem;
}

.dashboard-card-subtitle-9 {
	font-size: 1.4rem;
}

/* CARD SIZE 8 */

.card-size-8>*>* {
	width: 60%;
}

.card-icon-8 {
	height: 18vh;
}

.dashboard-card-title-8 {
	font-size: 1.8rem;
}

.dashboard-card-subtitle-8 {
	font-size: 1.4rem;
}

/* CARD SIZE 7 */

.card-size-7>*>* {
	width: 60%;
}

.card-icon-7 {
	height: 18vh;
}

.dashboard-card-title-7 {
	font-size: 1.8rem;
}

.dashboard-card-subtitle-7 {
	font-size: 1.4rem;
}

/* CARD SIZE 6 */


.card-size-6>*>* {
	width: 60%;
}

.card-icon-6 {
	height: 18vh;
}

.dashboard-card-title-6 {
	font-size: 1.8rem;
}

.dashboard-card-subtitle-6 {
	font-size: 1.4rem;
}

/* CARD SIZE 5 */

.card-size-5>*>* {
	width: 60%;
}

.card-icon-5 {
	height: 14vh;
}

.dashboard-card-title-5 {
	font-size: 1.5rem;
}

.dashboard-card-subtitle-5 {
	font-size: 1.1rem;
}

/* CARD SIZE 4*/

.card-size-4>*>* {
	width: 60%;
}

.card-icon-4 {
	height: 14vh;
}

.dashboard-card-title-4 {
	font-size: 1.5rem;
}

.dashboard-card-subtitle-4 {
	font-size: 1.1rem;
}

/* CARD SIZE 3 */

.card-size-3>*>* {
	width: 80%;
}

.card-icon-3 {
	height: 12vh;
}

.dashboard-card-title-3 {
	font-size: 1.5rem;
}

.dashboard-card-subtitle-3 {
	font-size: 1.1rem;
}

/* CARD SIZE 2*/

.card-size-2>*>* {
	width: 90%;
}

.card-icon-2 {
	height: 12vh;
}

.dashboard-card-title-2 {
	font-size: 1.2rem;
}

.dashboard-card-subtitle-2 {
	font-size: 0.7rem;
	display: none;
}

/* CARD SIZE 1 */

.card-size-1>*>* {
	width: 90%;
}

.card-icon-1 {
	height: 12vh;
}

.dashboard-card-title-1 {
	font-size: 1.2rem;
}

.dashboard-card-subtitle-1 {
	font-size: 0.7rem;
	display: none;
}

/* MOBILE CSS */

.dashboard-mobile-title {
	font-weight: bold;
	font-size: 2rem;
}

.dashboard-mobile-subtitle {}

.dashboard-mobile-card-external-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}

.dashboard-mobile-card-container:nth-child(even) {
	padding-left: 8px;
}

.dashboard-mobile-card-container:nth-child(odd) {
	padding-right: 8px;
}

.dashboard-mobile-card-container {
	width: 45.5vw;
	height: 45.5vw;
	padding-bottom: 0px;
	margin-bottom: 16px;
}

.dashboard-mobile-card-container>* {
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.14);
	border-radius: 8px;

	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-top: 20px;
	padding: 0 10px;
}

.dashboard-mobile-card-container>*:hover {
	text-decoration: none !important;
}

.dashboard-mobile-card-icon {}

.dashboard-mobile-card-title {
	text-align: center;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: bold;
	margin-bottom: 0 !important;
}

.dashboard-mobile-card-subtitle {
	text-align: center;
	font-size: 0.8rem;
	line-height: 1rem;
	margin-bottom: 10px !important;
}

.dashboard-mobile-video {
	height: 30px;
}