.container {
    padding: 20px;
}

.container .body-text {
    margin-top: 15px;
}

.container .button-action {
    width: 30%;
	min-width: 250px;
    margin: 25px auto;
}

.container .button-action button {
    font-size: 20px;
    min-height: 40px;
    outline: none;
    width: 100%;
}

@media (max-width: 650px){
    .container .button-action {
        width: 60%;
    }
}