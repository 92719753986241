.event-page {
    padding: 15px;
}

.event-box {
    padding: 10px;
    max-width: 700px;
    color: hsl(213deg 32% 32%);
    margin-bottom: 10px;
}

.event-box h2, .event-box h3, .event-box h4 {
    color: hsl(220deg 91% 13%);
}

.incontro-con {
    margin-top: 15px;
}

.incontro-con h4 {
    color: hsl(213deg 32% 32%);
    font-size: 15px;
    margin-bottom: -5px;
}

.a-cura-di {
    margin-top: 15px;
}

.a-cura-di h5 {
    margin-bottom: -4px;
}

.prenota {
    width: 50%;
}

.description {
    width: 100%;
}

.mostra-evento {
    width: 50%;
}

.mostra-evento a {
    background-color: #0065F2;
    border: 4px solid #0065F2;
    border-radius: 12px;
    font-size: 18px;
    padding: 6px;
    display: block;
    width: 40%;
    color: white;
    text-decoration: none;
    outline: none;
    display: flex;
    justify-content: center;
}
.prenota button {
    background-color: #0065F2;
    border: 4px solid #0065F2;
    border-radius: 12px;
    font-size: 18px;
    padding: 6px;
    width: 40%;
    color: white;
    text-decoration: none;
    outline: none;
}

@media (max-width: 700px){
    .prenota {
        width: 100%;
    }

    .prenota button {
        width: 100%;
    }
}