.footer-class {
    font-size: 30px;
    font-weight: 200;
    color: black;
}

.mobile-newsletter-footer {
	width: 100%;
	text-align: left;
	margin-bottom: 8vh;
}

.mobile-newsletter-footer > h1 {
	padding-right: 10%;
}

.mobile-newsletter-footer > button {
	width: 100%;
	margin-top: 4vh;
	font-size: 1.3rem;
	line-height: 6vh;
}

footer {
	width: calc(100% + 192px) !important;
	margin: 0 -96px 0 -96px !important;
	padding: 0 96px !important;
    border-top: 1px solid #165BE6;
}

@media (max-width: 570px) {
	footer {
		border-top: none;
		width: 100% !important;
		padding: 1.5rem 0 !important;
		margin: initial !important;
	}
}

.footer-mobile-logo-container {
	border-top: 2px solid lightgray;
	padding-top: 2vh;
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.footer-mobile-logo-container > a {
	width: 100%;
}

.footer-mobile-logo-container > a > img {
	width: 53%;
	padding-left: 2%;
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.footer-mobile-socialmedia-container {
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}
.footer-mobile-socialmedia-container > * {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.footer-p-socialmedia {
	font-size: 1.2rem;
}

.copyright {
    color: rgb(151, 152, 154);
    font-size: 20px;
    background-color: rgb(237, 237, 237)
}

.action-btn {
    margin-top: 50px;
    margin-left: 100px;
    background-color: rgb(0, 101, 242);
    border-color: rgb(0, 101, 242);
    height: 70px;
    width: 200px;
    cursor: pointer;
    color: white;
    width: 500px;
    font-size: 25px;
}

.gray-text {
	color: #16181E;
	opacity: 0.4;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.footer-info {
    font-size: 12px !important;
    margin: 0;
}

.foot-logo {
	margin-top: 16px;
	/* filter: invert(100%) sepia(66%) saturate(1847%) hue-rotate(179deg) brightness(80%) contrast(80%); */
	height: 24px !important;
	width: 24px !important;
	margin-right: 16px;
}

.footer-p a {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;    
    color: black;
}

.copyright-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    color: #16181E;
    margin-top: 12px;
}

.logo-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}


.central-footer {
    margin-left: 94px;
}

.footer-u {
    margin-left: 16px;
}

/* a:link {
    color: black;
    text-decoration: none;
} */

.footer-p-small {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
}

.footer-icons {
    /* margin-left: 120px; */
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.footer-icons > div {
	padding: 0;
	justify-content: flex-end;
}

.central-footer-spacing {
    margin-top: -10px;
}

.footer-row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.footer-row > * {
	padding: 0 !important;
}
.footer-icon-container {
}

.footer-wrapper {
	width: 100%;
    order: 3;
}

.footer-desktop {	
	display: block;
}

.logo-salto-footer {
    width: 196px;
    height: 60px;
}

.footer-mobile-last-text {
	padding-left: 0 !important;
}
.footer-mobile-last-text > a > p {
	font-size: 1.2rem;
	color:  #00132F !important;
	font-weight: 300;
	opacity: 1;
	margin-bottom: 10px !important;
}