.save-profile {
    background: #0065f2;
    color: white;
    border: 4px solid #0065f2;
    border-radius: 18px;
    cursor: pointer;
    white-space: pre-line;
    text-decoration: none;
    padding: 5px 15px;
    max-width: 100%;
}

.extraClassName-multiple-select {
    border-radius: 10px;
    padding: 0 14px;
    background: rgba(127, 127, 127, 0.2);
    margin-top: 34px;
    height: 35px;
}

.extraClassName-multiple-select .preview-multi-select .text-preview {
    font-size: 18px;
    font-weight: 500;
}

.extraClassName-multiple-select .preview-multi-select svg {
    width: 15px;
    height: 22px;
}

.extraClassName-multiple-select .preview-multi-select svg path{
    fill: #948f8f;
}